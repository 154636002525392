import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { Form, Field, Formik } from "formik";
import * as Yup from "yup";
import Rating from "./Rating";

export default function FeedbackForm({
  onRatingComplete,
  handleSubmit,
  feedback,
  setFeedback,
  store,
  receiptNumber = null,
  forAllHome = false,
}) {
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  let validationShape = {
    store_location: Yup.string().required("Store location is required"),
    name: Yup.string().required("Name is required"),
    contact_number: Yup.string()
      .required("Contact number is required")
      .matches(phoneRegExp, "Contact number is not valid"),
  };

  const FeedbackSchema = Yup.object().shape(validationShape);

  const changeRateHandler = (id, value) => {
    const actualValue = value === 6 ? "NA" : value;
    setRatingValues({
      ...ratingValues,
      [id]: actualValue,
    });
  };

  const [ratingCompleted, setRatingCompleted] = useState(false);
  const [ratingValues, setRatingValues] = useState({
    service_of_employees: null,
    product_knowledge: null,
    overall_cleanliness: null,
    transactions: null,
    overall_experience: null,
  });

  useEffect(() => {
    // check if all rating are completed
    const hasEmpty = Object.values(ratingValues).filter((num) => !num);
    if (!hasEmpty.length) {
      setRatingCompleted(true);
      return onRatingComplete(ratingValues);
    }
  }, [ratingValues]);

  const ratings = [
    {
      id: "service_of_employees",
      title: "Service and attentiveness of our employees.",
    },
    {
      id: "product_knowledge",
      title: "Product and Store knowledge of our employees.",
    },
    {
      id: "overall_cleanliness",
      title: "Overall cleanliness and look of store.",
    },
    {
      id: "transactions",
      title: "Transactions at the cashier were smooth and efficient.",
    },
    {
      id: "overall_experience",
      title: `Your overall experience at ${forAllHome ? "AllHome" : "AllDay"}.`,
    },
  ];

  return (
    <>
      <div className={`app-body ${forAllHome ? "all-home" : ""}`}>
        <div className="rating-area">
          {ratings.map((rating) => {
            const { id, title, value } = rating;
            return (
              <Rating
                number={6}
                title={title}
                id={id}
                key={`${id}+${value}`}
                onChangeRate={changeRateHandler}
              />
            );
          })}
        </div>

        <div className="form-area">
          <div className="form-group special">
            <label>Do you have any suggestions you'd like to share?</label>
            <textarea
              className="form-control"
              rows={4}
              placeholder="Write something... (Optional)"
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
            ></textarea>
          </div>
          <Formik
            initialValues={{
              receipt_number: receiptNumber,
              store_location: store[0]?.store_name,
              name: "",
              contact_number: "",
              // pos_terminal: '',
              // cashier: ''
            }}
            validationSchema={FeedbackSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {function Render({ isSubmitting }) {
              return (
                <Form>
                  <div className="separator"></div>

                  <div className="form-group">
                    <Field name="store_location">
                      {({ field, form }) => (
                        <>
                          <label htmlFor="store_location">Store Location</label>
                          <input
                            {...field}
                            type="text"
                            id="store_location"
                            className="form-control"
                            disabled={true}
                          />
                          <span className="error">
                            {form.errors.store_location &&
                              form.touched.store_location &&
                              form.errors.store_location}
                          </span>
                        </>
                      )}
                    </Field>
                  </div>

                  {receiptNumber && (
                    <div className="form-group">
                      <Field name="receipt_number">
                        {({ field, form }) => (
                          <>
                            <label htmlFor="receipt_number">
                              Receipt number
                            </label>
                            <input
                              {...field}
                              type="text"
                              id="receipt_number"
                              className="form-control"
                              disabled={true}
                            />
                            <span className="error">
                              {form.errors.receipt_number &&
                                form.touched.receipt_number &&
                                form.errors.receipt_number}
                            </span>
                          </>
                        )}
                      </Field>
                    </div>
                  )}

                  <div className="form-group">
                    <Field name="name">
                      {({ field, form }) => (
                        <>
                          <label htmlFor="name">Name</label>
                          <input
                            {...field}
                            type="text"
                            id="name"
                            className="form-control"
                          />
                          <span className="error">
                            {form.errors.name &&
                              form.touched.name &&
                              form.errors.name}
                          </span>
                        </>
                      )}
                    </Field>
                  </div>

                  <div className="form-group">
                    <Field name="contact_number">
                      {({ field, form }) => (
                        <>
                          <label htmlFor="contact_number">Contact number</label>
                          <input
                            {...field}
                            type="number"
                            id="contact_number"
                            className="form-control"
                          />
                          <span className="error">
                            {form.errors.contact_number &&
                              form.touched.contact_number &&
                              form.errors.contact_number}
                          </span>
                        </>
                      )}
                    </Field>
                  </div>

                  <Button
                    type="submit"
                    className={`cta ${forAllHome ? "all-home" : ""}`}
                    disabled={isSubmitting || !ratingCompleted}
                  >
                    Send
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
}
