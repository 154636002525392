import { useState } from "react";
import { submitFeedback } from "../services/feedbackService";
import { useParams } from "react-router";
import FeedbackForm from "../components/FeedbackForm";
import ThankYou from "../components/ThankYou";
import { allDayStores, allHomeStores } from "../config/stores";
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export default function Home({ type }) {
  const [showToast, setShowToast] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [feedbackSent, setFeedbackSent] = useState(false);
  const [ratings, setRatings] = useState(null);
  const [ratingCompleted, setRatingCompleted] = useState(false);
  const {
    store_id,
    pos,
    pos_txn_number,
    receipt_number,
    transaction_date,
    transaction_time,
  } = useParams();
  const forAllHome = type === "allhome";
  const listOfStores = forAllHome ? allHomeStores : allDayStores;
  const selectedStore = listOfStores.filter(item => item.store_id === store_id);
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = async values => {
    const payload = {
      ...values,
      feedback,
      type: forAllHome ? "allhome" : "allday",
      ...ratings,
    };

    if (pos) payload.pos = pos;
    if (pos_txn_number) payload.pos_txn_number = pos_txn_number;
    if (transaction_date) payload.transaction_date = transaction_date;
    if (transaction_time) payload.transaction_time = transaction_time;

    try {
      const response = await submitFeedback(payload, forAllHome);
      if (response.success) {
        setFeedbackSent(true);
      } else {
        setShowToast(true);
      }
    } catch (err) {
      setShowToast(true);
    }
  };

  const onRatingComplete = values => {
    setRatings(values);
    setRatingCompleted(true);
  };

  return (
    <>
      <header className={`app-header ${forAllHome ? "all-home" : ""}`}>
        <img src={forAllHome ? "/logo-allhome.png" : "/logo.svg"} alt="logo" />

        <div className={`second-part ${forAllHome ? "all-home" : ""}`}>
          {!feedbackSent ? (
            <>
              <h5>Send us your feedback!</h5>
              <p>
                Your feedback is important to us. Please rate your store
                experience below to help us serve your even better.
                <br />1 being the least satisfied and 5 being highly satisfied.
              </p>
            </>
          ) : (
            <>
              <h5>Feedback submitted!</h5>
            </>
          )}
        </div>
      </header>

      {feedbackSent ? (
        <ThankYou forAllHome={forAllHome} />
      ) : (
        <FeedbackForm
          onRatingComplete={onRatingComplete}
          handleSubmit={handleSubmit}
          feedback={feedback}
          setFeedback={setFeedback}
          store={selectedStore ? selectedStore : []}
          receiptNumber={receipt_number || null}
          forAllHome={type === "allhome"}
        />
      )}

      <a
        href="#"
        className="privacy-policy-link"
        onClick={() => setShowModal(true)}>
        Privacy Policy
      </a>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        scrollable={true}>
        <Modal.Header closeButton>
          <Modal.Title>PRIVACY POLICY</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "30px" }}>
          <p>
            <strong>Personal Information Collected</strong>
          </p>

          <p>
            <strong>
              {forAllHome ? "Allhome Corp." : "ALL DAY MARTS INC."}
            </strong>{" "}
            (the "Company") understands that the security of your personal
            information is extremely important and it is committed to respecting
            your privacy and safeguarding your personal data.
          </p>

          <p>
            The Company collects Personal Information upon your
            availment/purchase of its products and/or services, upon your or by
            your interaction with us, using any of or through our website or all
            our other channels. "Personal Information" may include, but not
            limited to:
          </p>

          <div className="policy-list">
            <p>
              1. Your [including family members, friends, beneficiaries,
              attorneys, attorneys-in-fact, shareholders, beneficial owners,
              members, representatives who contact the Company or may be
              contacted by the Company, whenever applicable and relevant
              (collectively, the "Related Person/s"] name, address, e-mail
              address, phone number, gender, birthday, and age;)
            </p>

            <p>
              2. Technical information, such us, IP addresses, internet browser
              used, and web pages accessed, your login information and
              information about your visit to our websites including the full
              Uniform Resource Locators (URL) clickstream to, through and from
              our websites (including date and time), products you viewed or
              searched for, pages you accessed, page response times, download
              errors, lengths of visits to certain pages, page interaction
              information (such as scrolling, clicks and mouse-overs), and
              methods used to browse away from the page;
            </p>

            <p>
              3. Transaction details and purchase history which includes details
              of your purchases and other transactions made, through our
              websites or through other channels, your delivery address and
              details of the products/services purchased/availed;
            </p>

            <p>
              4. Payment details used for the availment/purchase of the products
              and/or services;
            </p>

            <p>
              5. Information collected about your participation in our
              promotions and competitions or attendance at our events as
              provided in your application forms, recordings you or we have
              made, details of your guests in connection with any promotions and
              competitions you have entered or won, or other information related
              to your attendance at events, including any access assistance
              requirements you may have;
            </p>

            <p>
              6. Information about your use of our chat rooms, message boards,
              social media pages or other interactive forums including any
              comments, photos, videos or other information that you post
              online;
            </p>

            <p>
              7. Marketing and communication preference and information to help
              us determine what products and services may be of interest to you;
            </p>

            <p>
              8. Correspondence with you including any feedback, complaints and
              comments from you via telephone, email or records of any online,
              paper or in-person correspondence and interactions between us. If
              you have communicated with us by phone, we will collect details of
              the phone number you used to call us and any information collected
              via a call recording; and
            </p>

            <p>
              9. Anti-fraud information relating to your situation, your
              creditworthiness or any criminal or fraudulent activities,
              provided to us by you or third parties.
            </p>
          </div>

          <p>
            <strong>Your Consent</strong>
          </p>

          <p>
            By using the Company website, mobile applications and other online
            services, you are consenting to the collection, generation, use,
            processing, storage, retention and disclosure of your Personal
            Information by the Company. You likewise consent to the disclosure
            of the Personal Information by the Company to its subsidiaries,
            affiliates, banks, financial institutions and third party service
            utility providers.
          </p>

          <p>
            In addition thereto, and with respect to Personal Information you
            disclosed or supplied about/from Related Person/s, it shall be your
            duty and responsibility to:
          </p>

          <div className="policy-list">
            <p>
              1. To inform said Related Person/s of the purpose/s for which
              his/her/their Personal Information have been disclosed or supplied
              to the Company for collection and processing;
            </p>

            <p>
              2. Obtain the necessary consent of said Related Person/s for the
              disclosure, collection and processing of his/her/their Personal
              Information;
            </p>

            <p>
              3. To inform the that such consent from said Related Person/s have
              been obtained
            </p>
          </div>

          <p>
            <strong>Use of Personal Information</strong>
          </p>

          <p>
            The Company shall use your Personal Information for the following
            purposes: to provide you with details and information regarding our
            products and services; to process your availment/purchase of our
            products and/or services, to conduct billing processing and other
            business transactions; to provide and manage products and services
            you have requested; to communicate effectively with you; to monitor
            activities and record our correspondence with you; to provide you
            with marketing materials; to understand our customers, and to
            develop and tailor our products and services; to run our promotions
            and competitions and our events; to prevent fraud; to conduct
            certain checks on you, such as KYC and credit checks; to improve and
            administer our websites, and to ensure that content is relevant; to
            reorganize or make changes to our business and to comply with legal
            and regulatory obligations.
          </p>

          <p>
            The Company may disclose your Personal Information to affiliates
            which means our third party partners, their subsidiaries, its
            ultimate holding company and its subsidiaries who may use it in
            connection with any of the purposes set out above. We will also
            share your personal data with third party service providers (such as
            providers of marketing, IT or administrative services) who may
            process it on our behalf for any of the purposes set out above. The
            Company may also disclose your Personal Information under any of the
            following circumstances: (i) required by law or by court
            decisions/processes; (ii) for information, update and marketing
            purposes; and (iii) for research purposes.
          </p>

          <p>
            <strong>
              Storage, Retention and Disposal of Personal Information
            </strong>
          </p>

          <p>
            Your Personal Information shall be retained for as long as the
            purpose for which it was collected, and such other purposes that you
            may have consented to from time to time, remains in effect and until
            such time as it is no longer required nor necessary to keep your
            information for any other legal, regulatory or business purposes.
          </p>

          <p>
            The Company shall retain your Personal Information for five (5)
            years from the date of last transaction. Thereafter, the Company
            shall dispose of your Personal Information anonymizing digital files
            personal information in a secure manner that would prevent
            unauthorized access, further processing or disclosure to any other
            party.
          </p>

          <p>
            <strong>Safeguard of Personal Information</strong>
          </p>

          <p>
            The Company fully recognizes the value of your personal information
            strives to maintain the confidentiality, integrity and availability
            of your personal information by employing physical, technological
            and procedural safeguards. The Company trains its employees to
            properly handle your information and in the event we engage other
            companies to provide services for us, we require them to protect
            personal information aligned with our own security standards. Access
            to Personal Information shall only be limited to authorized
            personnel.
          </p>

          <p>
            Where we have given you (or where you have chosen) a password that
            enables you to access certain parts of our website, you are
            responsible for keeping this password confidential. We ask you not
            to share a password with anyone.
          </p>

          <p>
            Unfortunately, the transmission of information via the Internet is
            not completely secure. Although we will do our best to protect your
            personal data, we cannot guarantee the security of your personal
            data transmitted to our website – any transmission is at your own
            risk. Once we have received your information, we will use strict
            procedures and security features to try to prevent unauthorized
            access.
          </p>

          <p>
            <strong>Accessing and Correcting Your Personal Information</strong>
          </p>

          <p>
            You are entitled to certain rights in relation to the Personal
            Information collected from you, including the right to access and
            correct your Personal Information being processed and object to the
            processing.
          </p>

          <p>
            You may send us an e-mail at helpdesk@allday.ph to request access
            to, correct and/or delete any Personal Information that you have
            provided to us. Please be advised, however, that the Company cannot
            delete your Personal Information without restricting or removing its
            ability to effectively address your availment/purchase of its
            products and/or services or if it believes the same would violate
            any law or legal requirement or cause the Personal Data to be
            incorrect.
          </p>

          <p>
            <strong>Our Commitment to Children's Online Privacy</strong>
          </p>

          <p>
            The Company does not knowingly accept, collect or solicit Personal
            Information from children under the age of 13. In the event that
            Personal Information was inadvertently collected from children under
            the age of 13, parents may either give consent to the collection,
            use and disclosure of the child's personal information or request
            the removal of the child's personal information, by contacting the
            Company.
          </p>

          <p>
            <strong>Changes to this Privacy Policy</strong>
          </p>

          <p>
            The Company reserves the right to change this Privacy Policy from
            time to time. In case of changes, the revised Privacy Policy will be
            posted on this page, and where appropriate notified to you by e-mail
            and will take effect immediately. Please check back frequently to
            see any updates or changes to this privacy policy.
          </p>

          <p>
            <strong>How to Contact Us</strong>
          </p>

          <p>
            In case of questions or concerns regarding this Privacy Policy, you
            may contact the Company at:
          </p>

          <p>
            Address: {forAllHome ? "Allhome Corp." : "AllDay Marts Inc."}{" "}
            Accounting Office, Level 4 Starmall Alabang, South Super Hi-way,
            Muntinlupa, Philippines
          </p>

          <p>
            E-mail Address: <a href="#">dpo@allvalue.ph</a>
          </p>

          <p>
            Telephone No/s: <a href="#">(02) 8880-1198</a>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer className="p-3" position="top-center">
        <Toast
          onClose={() => setShowToast(false)}
          bg="warning"
          delay={3000}
          show={showToast}
          autohide>
          <Toast.Body>
            {"An error was encountered while submitting your feedback."}
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
}
