export const allDayStores = [
  {
    store_name: "Cerritos Mart",
    store_id: "M502",
  },
  {
    store_name: "Ponticelli Mart",
    store_id: "M503",
  },
  {
    store_name: "Shaw Supermarket",
    store_id: "M801",
  },
  {
    store_name: "Taguig Supermarket",
    store_id: "M802",
  },
  {
    store_name: "Sta. Rosa Supermarket",
    store_id: "M803",
  },
  {
    store_name: "Bataan Supermarket",
    store_id: "M804",
  },
  {
    store_name: "Molino Supermarket",
    store_id: "M805",
  },
  {
    store_name: "Kawit Supermarket",
    store_id: "M806",
  },
  {
    store_name: "Las Pinas Supermarket",
    store_id: "M807",
  },
  {
    store_name: "Libis Supermarket",
    store_id: "M808",
  },
  {
    store_name: "Pampanga Supermarket",
    store_id: "M809",
  },
  {
    store_name: "Global South",
    store_id: "M810",
  },
  {
    store_name: "Naga Supermarket",
    store_id: "M811",
  },
  {
    store_name: "Iloilo Supermarket",
    store_id: "M812",
  },
  {
    store_name: "General Trias Supermarket",
    store_id: "M813",
  },
  {
    store_name: "Tanza Supermarket",
    store_id: "M814",
  },
  {
    store_name: "Evia Supermarket",
    store_id: "M815",
  },
  {
    store_name: "Malolos Supermarket",
    store_id: "M816",
  },
  {
    store_name: "Augustine Groove Supermarket",
    store_id: "M817",
  },
  {
    store_name: "North Molino Supermarket",
    store_id: "M818",
  },
  {
    store_name: "Allday Supermarket Alabang",
    store_id: "M819",
  },
  {
    store_name: "East Lake Supermarket",
    store_id: "M839",
  },
  {
    store_name: "WCC Supermarket",
    store_id: "M840",
  },
];

export const allHomeStores = [
  {
    store_name: "AllBuilders Gapan",
    store_id: "B101",
  },
  {
    store_name: "AllBuilders Vibal",
    store_id: "B102",
  },
  {
    store_name: "San Jose",
    store_id: "H101",
  },
  {
    store_name: "Agro",
    store_id: "H102",
  },
  {
    store_name: "Pampanga",
    store_id: "H103",
  },
  {
    store_name: "Alabang",
    store_id: "H104",
  },
  {
    store_name: "Taguig",
    store_id: "H105",
  },
  {
    store_name: "Sta. Rosa",
    store_id: "H107",
  },
  {
    store_name: "Las Pinas",
    store_id: "H108",
  },
  {
    store_name: "Imus",
    store_id: "H109",
  },
  {
    store_name: "Bataan",
    store_id: "H110",
  },
  {
    store_name: "Wiltower",
    store_id: "H111",
  },
  {
    store_name: "Cebu",
    store_id: "H112",
  },
  {
    store_name: "Daang Hari",
    store_id: "H113",
  },
  {
    store_name: "Antipolo",
    store_id: "H114",
  },
  {
    store_name: "Shaw",
    store_id: "H115",
  },
  {
    store_name: "Evia",
    store_id: "H116",
  },
  {
    store_name: "Kawit",
    store_id: "H117",
  },
  {
    store_name: "Global South",
    store_id: "H118",
  },
  {
    store_name: "Libis",
    store_id: "H120",
  },
  {
    store_name: "Evia",
    store_id: "H121",
  },
  {
    store_name: "Naga",
    store_id: "H122",
  },
  {
    store_name: "Iloilo",
    store_id: "H123",
  },
  {
    store_name: "Cagayan De Oro",
    store_id: "H124",
  },
  {
    store_name: "General Trias",
    store_id: "H125",
  },
  {
    store_name: "Tanza",
    store_id: "H126",
  },
  {
    store_name: "Malolos",
    store_id: "H127",
  },
  {
    store_name: "Augustine Groove",
    store_id: "H128",
  },
  {
    store_name: "North Molino",
    store_id: "H129",
  },
  {
    store_name: "Allhome Silang",
    store_id: "H130",
  },
  {
    store_name: "Allhome Santiago",
    store_id: "H131",
  },
  {
    store_name: "Allhome Sto. Tomas",
    store_id: "H132",
  },
  {
    store_name: "Allhome Sta. Maria",
    store_id: "H133",
  },
  {
    store_name: "Allhome Butuan",
    store_id: "H134",
  },
  {
    store_name: "Allhome Cabanatuan",
    store_id: "H135",
  },
  {
    store_name: "Allhome Koronadal",
    store_id: "H136",
  },
  {
    store_name: "Allhome San Ildefonso",
    store_id: "H137",
  },
  {
    store_name: "Allhome Bacolod",
    store_id: "H138",
  },
  {
    store_name: "Allhome Cauayan",
    store_id: "H139",
  },
  {
    store_name: "Allhome Sapangpalay",
    store_id: "H140",
  },
  {
    store_name: "Allhome Balayan",
    store_id: "H141",
  },
  {
    store_name: "Allhome Bulakan",
    store_id: "H142",
  },
  {
    store_name: "Allhome Cagayan",
    store_id: "H143",
  },
  {
    store_name: "AllHome Gensan",
    store_id: "H144",
  },
  {
    store_name: "AllHome Tagum",
    store_id: "H145",
  },
  {
    store_name: "Mintal",
    store_id: "H146",
  },
  {
    store_name: "AllHome Caloocan",
    store_id: "H147",
  },
  {
    store_name: "AllHome Mactan",
    store_id: "H148",
  },
  {
    store_name: "AllHome Marcos Alvarez",
    store_id: "H149",
  },
  {
    store_name: "AllHome WCC",
    store_id: "H150",
  },
  {
    store_name: "Mamplasan Warehouse",
    store_id: "H801",
  },

  {
    store_name: "Tierra Nevada",
    store_id: "HS01",
  },
  {
    store_name: "Citta Italia",
    store_id: "HS02",
  },
  {
    store_name: "Springville",
    store_id: "HS03",
  },
  {
    store_name: "Masibay",
    store_id: "HS04",
  },
  {
    store_name: "Shaw 515",
    store_id: "HS05",
  },
  {
    store_name: "QF LAS PINAS",
    store_id: "HS06",
  },
  {
    store_name: "SS Evia",
    store_id: "HS07",
  },
  {
    store_name: "Symphony",
    store_id: "HS08",
  },
  {
    store_name: "Presidio",
    store_id: "HS09",
  },
  {
    store_name: "Venezia",
    store_id: "HS10",
  },
  {
    store_name: "The Farm",
    store_id: "HS11",
  },
  {
    store_name: "Maia Alta",
    store_id: "HS12",
  },
  {
    store_name: "Woodberry",
    store_id: "HS13",
  },
  {
    store_name: "AllDigital Evia",
    store_id: "HS14",
  },
  {
    store_name: "Pet Shop Evia",
    store_id: "HS15",
  },
  {
    store_name: "AllDigital Sta. Rosa",
    store_id: "HS16",
  },
  {
    store_name: "PetBuddy Sta. Rosa",
    store_id: "HS17",
  },
  {
    store_name: "PetBuddy NoMo",
    store_id: "HS18",
  },
  {
    store_name: "Camella East",
    store_id: "HS19",
  },
  {
    store_name: "PetBuddy Bataan",
    store_id: "HS20",
  },
  {
    store_name: "PetBuddy Global South",
    store_id: "HS21",
  },
  {
    store_name: "PetBuddy Taguig",
    store_id: "HS22",
  },
  {
    store_name: "PetBuddy Malolos",
    store_id: "HS23",
  },
  {
    store_name: "AllDigital Nomo",
    store_id: "HS24",
  },
  {
    store_name: "PetBuddy Somo",
    store_id: "HS25",
  },
  {
    store_name: "PetBuddy Antipolo",
    store_id: "HS26",
  },
];
