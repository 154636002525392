import React from "react";

export default function RatingItem({ id, number, onClick, selected }) {
  return (
    <div
      key={number}
      className={`rating-item ${selected === number ? "selected" : ""}`}
      key={number}
      onClick={() => {
        return onClick(number, id);
      }}
    >
      <span>{number === 6 ? "NA" : number}</span>
    </div>
  );
}
