import "./App.scss";
import { useEffect } from "react";
import Home from "./pages/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  const domain = window.location.hostname;
  const isAllHome = domain === process.env.REACT_APP_ALL_HOME_DOMAIN;

  useEffect(() => {
    document.title = isAllHome ? "All Home Feedback" : "All Day Feedback";
    const favicon = document.getElementById("icon");
    favicon.href = isAllHome ? "/favicon-allhome.ico" : "/favicon.ico";
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/:store_id/"
            element={<Home type={isAllHome ? "allhome" : "allday"} />}
          />
          <Route
            path="/:store_id/:pos"
            element={<Home type={isAllHome ? "allhome" : "allday"} />}
          />
          <Route
            path="/:store_id/:pos/:pos_txn_number"
            element={<Home type={isAllHome ? "allhome" : "allday"} />}
          />
          <Route
            path="/:store_id/:pos/:pos_txn_number/:receipt_number"
            element={<Home type={isAllHome ? "allhome" : "allday"} />}
          />
          <Route
            path="/:store_id/:pos/:pos_txn_number/:receipt_number/:transaction_date"
            element={<Home type={isAllHome ? "allhome" : "allday"} />}
          />
          <Route
            path="/:store_id/:pos/:pos_txn_number/:receipt_number/:transaction_date/:transaction_time"
            element={<Home type={isAllHome ? "allhome" : "allday"} />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
