import React, { useState, useEffect } from "react";
import RatingItem from "./RatingItem";

export default function Rating({
  number,
  title,
  id,
  onChangeRate
}) {

  const [items, setItems] = useState([])
  const [selectedNumber, setSelectedNumber] = useState(null)

  const onClick = (value, id) => {
    onChangeRate(id, value)
    setSelectedNumber(value)
  }

  useEffect(() => {
    let values = [];
    for (let i = 1; i <= number; i++) {
      values.push(i)
    }
    setItems(values)
  }, [])

  return (
    <div className="rating-block">
      <p>{title}</p>
      <div className="rating-container">
        { items.length &&
            items.map((num, index) => (
              <RatingItem
                id={id}
                number={num}
                onClick={onClick}
                key={index}
                selected={selectedNumber}
              />
            ))
        }
      </div>
    </div>
  )
}
