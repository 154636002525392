import { useEffect, useState } from "react"

export default function ThankYou({ forAllHome }) {

  let [counter, setCounter] = useState(5)
  const link = forAllHome ? 'https://www.allhome.com.ph' : 'https://www.allday.com.ph'

  useEffect(() => {
    setInterval(() => {
      setCounter(counter--)

      if (counter === 0) {
        window.location.href = link
      }
    }, 900)
  }, [])

  return (
    <div className="thank-you">
      <h5>Thank you for submitting your feedback!</h5>

      <h4>{`Redirecting to ${link} in`}</h4>
      <p>{counter} seconds</p>
    </div>
  )
}